import React from 'react';
import '../assets/styling/post.scss';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Head from '../components/Head';
import { categories } from '../assets/options';

export default function Template({ data, location: { pathname }, ...props }) {
  const posts = data.allMdx.nodes;
  const { category } = posts[0].frontmatter;
  const categoryData = categories.filter((c) => c.slug === category)[0];

  const { currentPage, numPages } = props.pageContext;
  const prevPage = currentPage - 1 === 1 ? `/${category}` : `/${category}/${(currentPage - 1).toString()}`;
  const nextPage = `/${category}/${(currentPage + 1).toString()}`;

  return (
    <>
      <Head
        title={categoryData.text}
        description={categoryData.description}
        category={categoryData.slug}
      />
      <Header path={pathname} category={categoryData.slug} />
      <main>
        <h1>{categoryData.text}</h1>
        <p className="tagline">{categoryData.description}</p>
        <div className="posts-grid">
          {
            posts.map(((p) => {
              const image = getImage(p.frontmatter.thumbnail);
              return (
                <div key={p.id}>
                  <a href={`/${p.frontmatter.category}/${p.frontmatter.slug}`}>
                    <GatsbyImage image={image} />
                    <h2>{p.frontmatter.title}</h2>
                    <p className="post-meta">
                      {p.frontmatter.author}
                      {' '}
                      -
                      {' '}
                      {new Date(p.frontmatter.date).toLocaleDateString()}
                    </p>
                    <p>{p.frontmatter.description}</p>
                  </a>
                </div>
              );
            }))
          }
        </div>
        <div className="posts-pagination">
          {currentPage > 1 && (
            <Link to={prevPage} rel="prev">
              ← Vorherige Seite
            </Link>
          )}
          {currentPage < numPages && (
          <Link to={nextPage} rel="next">
            Nächste Seite →
          </Link>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}

export const postQuery = graphql`
query CategoryPageQuery($category: String, $skip: Int!, $limit: Int!) {
  allMdx(
    filter: {frontmatter: {category: {eq: $category}}}
    sort: {fields: frontmatter___date, order: DESC}
    limit: $limit
    skip: $skip
  ) {
    nodes {
      frontmatter {
        title
        description
        id
        category
        slug
        author
        date
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              width: 430
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
}
`;
